import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../constants/axiosService';
import { SERVER_URL } from '../../constants/defaultValues';

export const fetchTestResult = createAsyncThunk('testResult/fetch', async (id) => {
  try {
    return axios.get(`${SERVER_URL}test_result/${id}`);
  } catch (error) {
    throw new Error('Error fetching result');
  }
});

const TestResultSlice = createSlice({
  name: 'TestResultSlice',
  initialState: {
    items: [],
    counter: 0,
    status: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestResult.pending, (state) => ({ ...state, status: 'loading' }))
      .addCase(fetchTestResult.fulfilled, (state) => ({ ...state, status: 'success' }))
      .addCase(fetchTestResult.rejected, (state, action) => ({ ...state, status: 'failed', error: action.error.message }));
  },
});

export const { increment, decrement } = TestResultSlice.actions;
export default TestResultSlice.reducer;
