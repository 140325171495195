import _ from 'lodash';
import { getIsCAdminRole, getIsUserRole } from '../helpers/getUserRole';

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

export const defaultMenuType = 'menu-default';
export const defaultDateFormat = 'MM/DD/YYYY HH:mm';
export const readableDateFormat = 'MMM D, YYYY hh:mm A';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const pageSize = Math.ceil(window.innerHeight / 30);

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';

export const zendeskHelpPageUrl = 'https://help.moichor.com/knowledge';
export const moichorTermsUrl = 'https://moichor.com/terms-conditions';
/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = 'light.blue';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';

export const inDollars = (num) => ` $${(num / 100).toFixed(2)}`;

export const billingStatuses = {
  created: {
    is_paid: false,
    is_refunding: false,
    is_refunded: false,
    is_billed: false,
    is_cancelled: false,
  },
  paid: {
    is_paid: true,
    is_refunding: false,
    is_refunded: false,
    is_billed: true,
    is_cancelled: false,
  },
  cancelled: {
    is_paid: false,
    is_refunding: false,
    is_refunded: false,
    is_billed: false,
    is_cancelled: true,
  },
  refunding: {
    is_paid: true,
    is_refunding: true,
    is_refunded: false,
    is_billed: false,
    is_cancelled: false,
  },
  refundingAndBilled: {
    is_paid: true,
    is_refunding: true,
    is_refunded: false,
    is_billed: true,
    is_cancelled: false,
  },
  refunded: {
    is_paid: true,
    is_refunding: false,
    is_refunded: true,
    is_billed: true,
    is_cancelled: false,
  },
  billed: {
    is_paid: false,
    is_refunding: false,
    is_refunded: false,
    is_billed: true,
    is_cancelled: false,
  },
};

export const billingStatusNames = {
  created: 'created',
  billed: 'billed',
  paid: 'paid',
  refunding: 'refunding',
  refundingAndBilled: 'refund',
  refunded: 'refunded',
  cancelled: 'cancelled',
};

export const getStatusName = (order = {}) => {
  const status = {
    is_paid: order.is_paid,
    is_refunding: order.is_refunding,
    is_refunded: order.is_refunded,
    is_billed: order.is_billed,
    is_cancelled: order.is_cancelled,
  };

  if (_.isEqual(status, billingStatuses.cancelled)) return billingStatusNames.cancelled;
  if (_.isEqual(status, billingStatuses.refundingAndBilled)) return billingStatusNames.refundingAndBilled;
  if (_.isEqual(status, billingStatuses.refunding)) return billingStatusNames.refunding;
  if (_.isEqual(status, billingStatuses.refunded)) return billingStatusNames.refunded;
  if (_.isEqual(status, billingStatuses.paid)) return billingStatusNames.paid;
  if (_.isEqual(status, billingStatuses.created)) return billingStatusNames.created;
  if (_.isEqual(status, billingStatuses.billed)) return billingStatusNames.billed;
  return 'other';
};

/*
API references
*/

export const BASE_API = process.env.REACT_APP_BASE_API;
export const SERVER_URL = `${BASE_API}/api/v1/`;

/*
  Order options
*/

export const patientListOrderOptions = [
  { column: 'name', label: "Patient's name" },
  { column: 'lname', label: "Client's last name" },
  { column: 'species', label: "Patient's species" },
];

export const clientListOrderOptions = [
  { column: 'fname', label: "Client's first name" },
  { column: 'lname', label: "Client's last name" },
];

export const vetListOrderOptions = [
  { column: 'fname', label: "Vet's first name" },
  { column: 'lname', label: "Vet's last name" },
];

export const paymentListOrderOptions = [
  { column: 'issue_date', label: 'Date issued: newer' },
  { column: 'issue_date_older', label: 'Date issued: older' },
  { column: 'payment_status', label: 'Invoice Status' },
];

export const testListOrderOptions = [
  { column: 'dateCreated', label: 'Date created: newer' },
  { column: 'dateCreatedOld', label: 'Date created: older' },
];

export const suppliesListOrderOptions = [
  { column: 'dateCreated', label: 'Date created: newer' },
  { column: 'dateCreatedOld', label: 'Date created: older' },
  // { column: 'supplyStatus', label: 'Shipment Status' },
];

export const testStatus = {
  completed: 2,
  created: 4,
  failure: 8,
  pending: 16,
  in_validation: 32,
  in_review: 64,
  in_shipment: 128,
  pathology_review: 256,
  review_completed: 512,
  sample_received: 1024,
  processing: 2048,
  preliminary_report: 4096,
};

export const sidebarStatusClasses = {
  sm: {
    SHOW: 'menu-default menu-sub-hidden main-hidden sub-hidden menu-mobile main-show-temporary sub-show-temporary',
    SUB_HIDDEN: 'menu-default menu-sub-hidden main-hidden sub-hidden menu-mobile',
    HIDDEN: 'menu-default menu-sub-hidden main-hidden sub-hidden menu-mobile main-show-temporary',
  },
  lg: {
    SHOW: 'menu-default',
    SUB_HIDDEN: 'menu-default sub-hidden',
    HIDDEN: 'menu-default sub-hidden main-hidden',
  },
  md: {
    SHOW: 'menu-default menu-sub-hidden sub-show-temporary',
    SUB_HIDDEN: 'menu-default menu-sub-hidden',
    HIDDEN: 'menu-default menu-sub-hidden main-hidden sub-hidden',
  },
};

export const getMessageInfo = (roles, userId) => {
  if (getIsUserRole(roles)) {
    return {
      title: `Clinic User${userId ? ` #${userId}` : ''}`,
    };
  } if (getIsCAdminRole(roles)) {
    return {
      title: 'Moichor Clinical',
    };
  }
  return {
    title: 'Moichor System',
  };
};

export const variableType = {
  NUMBER: 0,
  TEXT: 1,
  CATEGORICAL: 2,
  MULTICATEGORICAL: 3,
  GRAPHIC: 4,
};
