/* eslint-disable no-console */
import React, { useCallback, useEffect } from 'react';
import axios from 'axios';

import packageJson from '../../package.json';

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const CacheBuster = ({ children }) => {
  const clearCache = useCallback(() => {
    if (caches) {
      caches.keys()
        .then((keys) => keys.forEach((name) => caches.delete(name)));
    }
    window.location.reload();
  }, []);

  useEffect(() => {
    const fetchMeta = async () => {
      const { data } = await axios.get('/meta.json');
      if (semverGreaterThan(data.version, global.appVersion)) {
        console.log(`Loaded new version ${data.version} over ${global.appVersion}`);
        clearCache();
      } else console.log('App version is up to date');
    };
    fetchMeta();
  }, [clearCache]);

  return (
    <>
      {children}
    </>
  );
};

export default CacheBuster;
