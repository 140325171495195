import localStorageKeys from '../../constants/localStorageKeys';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  LOGIN_USER_2FA,
  LOGIN_USER_SUCCESS_2FA,
  LOGIN_USER_ERROR_2FA,
  SWITCH_ACTIVE_CLINIC,
} from './actions';

export const INIT_STATE = {
  user: JSON.parse(localStorage.getItem(localStorageKeys.moichorUser)),
  activeClinic: parseInt(localStorage.getItem(localStorageKeys.moichorActiveClinic), 10) || null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state, loading: false, user: action.payload, error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state, loading: false, user: '', error: (action.payload) ? action.payload.message : '',
      };
    case LOGIN_USER_2FA:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS_2FA:
      return {
        ...state, loading: false, user: action.payload, error: '',
      };
    case LOGIN_USER_ERROR_2FA:
      return {
        ...state, loading: false, user: '', error: (action.payload) ? action.payload.message : '',
      };
    case LOGOUT_USER:
      return { ...state, user: null, error: '' };
    case GET_CURRENT_USER:
      return { ...state, loading: false };
    case GET_CURRENT_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case SWITCH_ACTIVE_CLINIC:
      return { ...state, activeClinic: action.payload };
    default: return { ...state };
  }
};
