const localStorageKey = {
  moichorToken: 'moichor-token',
  moichorRefreshToken: 'moichor-refresh-token',
  moichorResetToken: 'moichor-reset-token',
  direction: 'direction',
  moichorUser: 'moichor-user',
  moichorQRCode: 'moichor-qr-code',
  moichorActiveClinic: 'moichor-active-clinic',
  moichorAIConsulationRequest: 'moichor-ai-consultation-request',
};
export default localStorageKey;
