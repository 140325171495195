export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';

export const LOGIN_USER_2FA = 'LOGIN_USER_2FA';
export const LOGIN_USER_SUCCESS_2FA = 'LOGIN_USER_SUCCESS_2FA';
export const LOGIN_USER_ERROR_2FA = 'LOGIN_USER_ERROR_2FA';
export const SWITCH_ACTIVE_CLINIC = 'SWITCH_ACTIVE_CLINIC';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

export const switchClinic = (clinicId) => ({
  type: SWITCH_ACTIVE_CLINIC,
  payload: clinicId,
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const loginUser2fa = (user, history) => ({
  type: LOGIN_USER_2FA,
  payload: { user, history },
});

export const loginUserSuccess2fa = (user) => ({
  type: LOGIN_USER_SUCCESS_2FA,
  payload: user,
});

export const loginUserError2fa = (message) => ({
  type: LOGIN_USER_ERROR_2FA,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const getCurrentUser = (history, path) => ({
  type: GET_CURRENT_USER,
  payload: { history, path },
});

export const getCurrentUserSuccess = (user) => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload: user,
});
