import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import store from './store';

import * as serviceWorker from './serviceWorker';
import UserContextProvider from './context/auth/UserProvider';
import CacheBuster from './views/CacheBuster';
import ErrorBoundary from './components/common/ErrorBoundary';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://0e05b3960cdbb616382bd19ea125be15@o488078.ingest.us.sentry.io/4507015551385600',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://v2-dev-portal.moichor.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

ReactDOM.render(
  <CacheBuster>
    <ErrorBoundary>
      <Router>
        <UserContextProvider>
          <Suspense fallback={<div className="loading" />}>
            <Provider store={store}>
              <App />
            </Provider>
          </Suspense>
        </UserContextProvider>
      </Router>
    </ErrorBoundary>
  </CacheBuster>,
  document.getElementById('root'),
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
