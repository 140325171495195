import { configureStore } from '@reduxjs/toolkit';
import TestResultReducer from './slices/TestResultSlice';

const store = configureStore({
  reducer: {
    counter: TestResultReducer,
  },
});

export default store;
