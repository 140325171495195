import React, {
  createContext, useReducer, useContext, useMemo, useCallback, useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../constants/axiosService';
import reducer, { INIT_STATE } from './reducer';
import {
  loginUserSuccess,
  loginUserError,
  loginUserSuccess2fa,
  loginUserError2fa,
  loginUser,
  loginUser2fa,
  logoutUser,
  getCurrentUser as getCurrentUserAction,
  switchClinic,
} from './actions';
import localStorageKeys from '../../constants/localStorageKeys';

const BASE_API = process.env.REACT_APP_BASE_API;

const UserContext = createContext({});
export const useUserContext = () => useContext(UserContext);

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const history = useHistory();
  const location = useLocation();
  const activeClinic = localStorage.getItem(localStorageKeys.moichorActiveClinic)
    ? parseInt(localStorage.getItem(localStorageKeys.moichorActiveClinic), 10)
    : null;

  const allPermissions = useMemo(() => {
    const permissions = state?.user?.permissions || [];
    (state?.user?.roles ?? []).forEach((role) => {
      permissions.push(...role.permissions);
    });
    return permissions.map((permission) => permission.slug);
  }, [state?.user?.roles, state?.user?.permissions]);

  const usePermission = useCallback((permission) => allPermissions.includes(permission), [allPermissions]);
  const getPermission = useCallback((permission) => allPermissions.includes(permission), [allPermissions]);

  const setUserClinics = (clinics) => {
    if (!clinics) return;
    if (clinics?.length === 1) {
      // If the user has only one clinic set it on local storage no check is required
      localStorage.setItem(localStorageKeys.moichorActiveClinic, clinics[0].id);
      dispatch(switchClinic(clinics[0].id));
      axios.defaults.headers.common['X-Active-Clinic'] = clinics[0].id;
    } else if (!localStorage.getItem(localStorageKeys.moichorActiveClinic) && clinics?.length > 0) {
      // If there is no any selected clinic on localstorage select the first one
      localStorage.setItem(localStorageKeys.moichorActiveClinic, clinics[0].id);
      dispatch(switchClinic(clinics[0].id));
      axios.defaults.headers.common['X-Active-Clinic'] = clinics[0].id;
    } else {
      // If there is selected clinic in local storage but that selection is not available in the current clinic's list of the logged in user
      // select and saved the first clinic of current list
      // else continue with the existing selection.
      const clinicId = localStorage.getItem(localStorageKeys.moichorActiveClinic);
      if (!clinics?.map((item) => item.id).includes(parseInt(clinicId, 10))) {
        localStorage.setItem(localStorageKeys.moichorActiveClinic, clinics[0].id);
        axios.defaults.headers.common['X-Active-Clinic'] = clinics[0].id;
      }
    }
  };

  const setLocalData = ({ accessToken, refreshToken }, user) => {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    localStorage.setItem(localStorageKeys.moichorToken, accessToken);
    localStorage.setItem(localStorageKeys.moichorRefreshToken, refreshToken);
    localStorage.setItem(localStorageKeys.moichorUser, JSON.stringify(user));
    dispatch(loginUserSuccess(user));
    dispatch(loginUser(user));
    setUserClinics(user.clinics || []);
  };

  const clearLocalData = () => {
    axios.defaults.headers.common.Authorization = null;
    localStorage.removeItem(localStorageKeys.moichorToken);
    localStorage.removeItem(localStorageKeys.moichorRefreshToken);
    localStorage.removeItem(localStorageKeys.moichorActiveClinic);
    localStorage.removeItem(localStorageKeys.moichorUser);
  };

  const selectActiveClinic = (clinicId) => {
    localStorage.setItem(localStorageKeys.moichorActiveClinic, clinicId);
    dispatch(switchClinic(clinicId));
  };

  const loginWithEmailPassword = async (user) => {
    const {
      email, password, code, recaptchaResponse,
    } = user;
    try {
      const { data } = await axios.post(`${BASE_API}/api/v1/auth/login`, {
        email, password, code, recaptchaResponse,
      });
      if (data) {
        const { user, accessToken, refreshToken } = data;
        if (user && accessToken && refreshToken) {
          setLocalData({ accessToken, refreshToken }, user);
          history.push((location.state && location.state.from.pathname) || '/');
        }
      }
      if (!data) dispatch(loginUserError('Error.'));
    } catch (error) {
      if (error?.data && error.data?.error) {
        dispatch(loginUserError(error.data?.error?.message || error.message || error));
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const loginWithCode = async (user) => {
    const { code, id, phoneNumber = null } = user;
    dispatch(loginUser2fa(user));
    try {
      const { data } = await axios.post(`${BASE_API}/signin-2fa`, { code, id, phoneNumber });

      if (data) {
        const { user, verified, authData } = data;
        if (verified) {
          if (authData) {
            setLocalData(authData, user);
            dispatch(loginUserSuccess2fa(user));
            localStorage.removeItem(localStorageKeys.moichorQRCode);
            history.push('/');
          }
        } else if (!verified) {
          dispatch(loginUserError2fa('The code was not verified. Please, try again.'));
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('login error : ', error);
    }
  };

  const logout = () => {
    dispatch(logoutUser());
    try {
      dispatch(loginUserSuccess(null));
      clearLocalData();
      history.push('/');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('logout failed :', error);
    }
  };

  const getCurrentUser = async () => {
    dispatch(getCurrentUserAction());
    try {
      const { data } = await axios.get(`${BASE_API}/api/v1/auth/profile`);
      if (data) {
        setUserClinics(data.clinics || []);
        dispatch(loginUserSuccess(data));
      } else {
        dispatch(loginUserSuccess(null));
        clearLocalData();
        history.push('/');
      }
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('login failed :', error);
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <UserContext.Provider value={{
      ...state,
      loginWithEmailPassword,
      loginWithCode,
      logout,
      getCurrentUser,
      usePermission,
      getPermission,
      selectActiveClinic,
      activeClinic,
      setLocalData,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const withUserContext = (Component) => (
  (props) => (
    <UserContext.Consumer>
      {(state) => <Component {...props} {...state} />}
    </UserContext.Consumer>
  )
);

export default UserContextProvider;
